import axios from "axios";
import { PrescriberSchema } from "../commons/schemas";
import { client, getBaseURL } from "~/utils/axiosClient.util";
import type {
  IClinicResponse,
  IPrescriberResponse,
  IReportDataResponse,
  SuccessResponse,
} from "~/types";

export async function createPrescriberAccount(
  prescriberData: PrescriberSchema.CreatePrescriber,
) {
  const baseURL = getBaseURL();
  const requestURL = `${baseURL}/account/signup/prescriber`;

  const { data } = await axios
    .post<SuccessResponse>(requestURL, prescriberData)
    .then((res) => res.data);

  return data;
}

export async function uploadPrescriberDocToS3(uploadURL: string, doc: File) {
  await axios.put(uploadURL, doc, {
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function savePrescriberDocument(url: string, type: string) {
  const baseURL = getBaseURL();
  const requestURL = `${baseURL}/account/document`;

  const { data } = await axios
    .post<SuccessResponse>(requestURL, {
      presigned_upload_url: url,
      document_type: type,
    })
    .then((res) => res.data);
  return data;
}

export async function loadPrescriberDetails() {
  const { result } = await client.get<IPrescriberResponse>("/account");
  return result;
}

export async function acceptTermsConditions(): Promise<void> {
  await client.patch<IPrescriberResponse>("/account", {
    accepted_terms_conditions: new Date().toISOString(),
  });
}

export async function loadClinicDetails() {
  const { result } = await client.get<IClinicResponse>("/account/clinic");
  return result;
}

export async function checkClinicOnboardingCode(code: string) {
  const baseURL = getBaseURL();
  const requestURL = `${baseURL}/signup/clinic_verification`;
  const { data } = await axios
    .post<SuccessResponse>(requestURL, {
      prescriber_onboarding_code: code,
    })
    .then((res) => res.data);
  return data;
}

export async function saveApiKey(apiKey: string) {
  await client.patch<SuccessResponse>("/account/cliniko", {
    cliniko_api_key: apiKey,
  });
}

export async function loadReport(year: number, month: number, options: string) {
  const response = await client.get<IReportDataResponse>(
    `/reports?year=${year}&month=${month}&options=${options}`,
  );
  return response.data.report;
}
